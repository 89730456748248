import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import MainSectionLogo from "../svg/main_section_logo.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";
import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import {useEffect} from "react";
import ChangeLanguage from "../components/changeLanguage";

const HologramCnPage = ({location}) => {

    React.useEffect(() => {
        const scrollContainer = document.querySelector('.site-wrapper');

        if(scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('_without-layout');
    }, []);

    return (
        <>
            <Helmet>
                <title>Hologram | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <section className="installation-img-section">
                <img className="" src="/static/img/5_Hologram.jpg" alt=""/>
            </section>

            <section className="site-section site-section--installation">
                <ChangeLanguage location={location}/>
                <div className="content-section" style={ { "--width": "72rem" } }>

                    <h2 className="content-section__title">HOLOGRAM</h2>

                    <div className="content-section__text">
                        <p>由澳洲媒體及製作團隊 IKONIX 設計的HOLOGRAM，即時運用雙手開啟互動感應裝置，操控畫面上的科幻星球，能縮細、放大、扭曲和旋轉，探索數碼宇宙，奧妙無窮。</p>
                    </div>


                    <div className="corners">
                        <p>請將雙手移到傳感器上方去控制星球</p>

                        <TopLeftCorner className="corners__corner corners__corner_top-left" />
                        <TopRightCorner className="corners__corner corners__corner_top-right" />
                        <BottomRightCorner className="corners__corner corners__corner_bottom-right" />
                        <BottomLeftCorner className="corners__corner corners__corner_bottom-left" />
                    </div>

                    <div className="green">
                        <h2 className="content-section__title">藝術團隊 — IKONIX</h2>
                        <div className="content-section__text">
                            <p>IKONIX 是一個由澳大利亞多媒體和製作專業人士組成的團隊。他們通過視頻、燈光、建築和音頻創作精美的裝置。 IKONIX 專注於超高分辨率投影和尖端 LED 技術，將環境和建築帶入了一種全新的境界，遍及澳大利亞和世界，現在終於在香港。</p>
                        </div>
                    </div>

                    <MainSectionLogo className="content-section__logo" />

                </div>
            </section>
        </>
    )
}

export default HologramCnPage;
